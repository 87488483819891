import React, {useEffect, useState} from "react"
import PropTypes from "prop-types"
import {showJetToast} from "../Toast";
import PrimaryButton from "../common/PrimaryButton";
import LinkedinOrganization from "./LinkedinOrganization";

const LinkedInAccountSelection = ({linkedInData, page}) => {

    const [loading, setLoading] = useState(false);
    const [loadingText, setLoadingText] = useState('');
    const personalAccountOrganization = {
        id: linkedInData["sub"],
        localizedName: linkedInData["name"],
        picture: linkedInData["picture"],
        type: 'personal'
    }
    const [selectedAccountId, setSelectedAccount] = useState(personalAccountOrganization.id);
    linkedInData["organizations"][personalAccountOrganization.id] = personalAccountOrganization;

    const handleAccountClick = () => {
        if (loading) return;
        setLoading(true);
        console.log(selectedAccountId);
        setLoadingText('Setting up your account...');
        fetch(`/pages/${page.id}/linkedin/select_account`, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                // @ts-ignore
                "X-CSRF-Token": document.querySelector("meta[name='csrf-token']").content
            },
            method: 'POST',
            body: JSON.stringify({account_id: selectedAccountId})
        }).then(response => response.json()).then(data => {
            if (data.error) {
            } else {
                setLoadingText('LinkedIn account connected successfully! Redirecting...');
                window.location.href = '/';
            }
        }).catch((error) => {
            setLoadingText('LinkedIn account connection failed. Redirecting...');
            // window.location.href = '/';
        }).finally(() => {
            setLoading(false);
        });
    }

    return (
        <div className="mt-12 flex justify-center items-center flex-col">
            <h1 className="font-bold text-2xl">Select LinkedIn Account or Organization</h1>
            {loading ? <div id="fb-spinner" className="hidden m-2">
                <svg className="animate-spin inline h-5 w-5 m-3" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"/>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v1a7 7 0 00-7 7h1z"/>
                </svg>
                <span className="sr-only">Loading...</span>
            </div>: null}
            {loadingText ? <div id="loading-text" className="m-4">{loadingText}</div>: null}
            {linkedInData["organizations"] ?
                <ul className="mt-4 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-4">
                    {Object.keys(linkedInData["organizations"]).map(organizationId => (
                        <li key={linkedInData["organizations"][organizationId].id}>
                            <LinkedinOrganization organization={linkedInData["organizations"][organizationId]}
                                            selected={selectedAccountId === linkedInData["organizations"][organizationId].id}
                                            handleAccountClick={() => setSelectedAccount(linkedInData["organizations"][organizationId].id)}/>
                        </li>
                    ))}
                </ul>: null}
            <div className="mt-8">
                <PrimaryButton text={"Confirm"} onClick={handleAccountClick} isLoading={loading}/>
            </div>
        </div>
    )
}

LinkedInAccountSelection.propTypes = {
    brand: PropTypes.object,
    accounts: PropTypes.array
};

export default LinkedInAccountSelection
