import React from "react"
import PropTypes from "prop-types"

const GhostButton = (props) => {

    if (props.isLoading) {
        return (
            <div className="rounded-lg py-3 text-center bg-caption">
                <div className={"text-white"}>
                    <svg className="animate-spin inline h-6 w-6 mx-3" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"/>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v1a7 7 0 00-7 7h1z"/>
                    </svg>
                </div>
            </div>

        )
    }

    return (
        <div onClick={() => props.onClick && props.onClick()}
             className={`rounded-lg font-bold py-3 text-center cursor-pointer px-5 block ${props.disabled ? 'bg-primaryLight text-white' : 'bg-white text-textCaption'}`}>
            {props.text}
        </div>
    )
}

GhostButton.propTypes = {
    text: PropTypes.string,
    onClick: PropTypes.func,
    isLoading: PropTypes.bool,
    disabled: PropTypes.bool
};
export default GhostButton;