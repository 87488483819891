import React from "react";
import {CarouselData} from "../../types/carouselData";
import {Input} from "../ui/input";
import { Turbo } from "@hotwired/turbo-rails";

interface SignatureSettingsProps {
    name: string;
    image_url: string;
    call_to_action_text: string;
    call_to_action_button_text: string;
    social_handle: string;
    onSignatureChange: (field: string) => (e: React.ChangeEvent<HTMLInputElement>) => void;
    pageId: number;
}

export const SignatureSettings: React.FC<SignatureSettingsProps> = ({
                                                                        name,
                                                                        image_url,
                                                                        call_to_action_button_text,
                                                                        call_to_action_text,
                                                                        social_handle,
                                                                        onSignatureChange,
    pageId
                                                                    }) => {
    const openPageEdit = () => {
        Turbo.visit(`/pages/${pageId}/edit`);
    }

    return (
        <div className="flex flex-col gap-4 m-4">
            <div className="mt-2 underline cursor-pointer" onClick={openPageEdit}>
                Edit the signature settings for all future posts in page settings.
            </div>
            <label>Name</label>
            <Input
                type="text"
                value={name}
                onChange={onSignatureChange('name')}
            />

            <label>Image URL</label>
            <Input
                type="url"
                value={image_url}
                onChange={onSignatureChange('image_url')}
            />

            <label>Call to Action Text</label>
            <Input
                type="text"
                value={call_to_action_text}
                onChange={onSignatureChange('call_to_action_text')}
            />

            <label>Call to Action Button Text</label>
            <Input
                type="text"
                value={call_to_action_button_text}
                onChange={onSignatureChange('call_to_action_button_text')}
            />
            <label>Social Handle</label>
            <Input
                type="text"
                value={social_handle}
                onChange={onSignatureChange('social_handle')}
            />
        </div>
    );
};
