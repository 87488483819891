import React, {useState} from "react";
import PropTypes from "prop-types";
import {
    Dialog,
    DialogTrigger,
    DialogContent,
    DialogHeader,
    DialogTitle,
    DialogDescription,
    DialogClose
} from "../ui/dialog";
import {Button} from "../ui/button";
import {Tabs, TabsContent, TabsList, TabsTrigger} from "../ui/tabs";
import PostTemplateList from "./PostTemplateList";

const PostTemplateSelectionModal = ({open, setOpen, selectedPostTemplate, setSelectedPostTemplate, setContent}) => {

    const [selectedCategory, setSelectedCategory] = useState(null);

    return (
        <Dialog open={open} onOpenChange={setOpen}>
            <DialogContent className="z-[1000] min-w-[90%] h-[95%] min-h-[95%] lg:h-[70%] lg:min-h-[70%] lg:min-w-[70%] flex flex-col overflow-scroll">
                <DialogHeader>
                    <DialogTitle>Post Templates</DialogTitle>
                    <DialogDescription>
                        Select from viral post templates or create your own.
                    </DialogDescription>
                </DialogHeader>
                <div className="">
                    <Tabs defaultValue="templates">
                        <TabsList className="bg-gray-200">
                            <TabsTrigger value="templates">Templates</TabsTrigger>
                            <TabsTrigger value="custom">Add Custom</TabsTrigger>
                            <TabsTrigger value="mytemplates">My Templates</TabsTrigger>
                        </TabsList>
                        <TabsContent value="templates">
                            <PostTemplateList
                                setShowTemplateSelectionModal={setOpen}
                                setContent={setContent}
                                selectedPostTemplate={selectedPostTemplate}
                                setSelectedPostTemplate={setSelectedPostTemplate} />
                        </TabsContent>
                        <TabsContent value="custom">
                        </TabsContent>
                        <TabsContent value="mytemplates">
                        </TabsContent>
                    </Tabs>
                </div>
            </DialogContent>
        </Dialog>
    );
};

PostTemplateSelectionModal.propTypes = {
    open: PropTypes.bool,
    setOpen: PropTypes.func
};

export default PostTemplateSelectionModal;
