import React, {useEffect, useState} from "react"
import PropTypes from "prop-types"
import PrimaryButton from "../common/PrimaryButton";
import {Turbo} from "@hotwired/turbo-rails";
import SocialPostCard from "../social_posts/SocialPostCard";
import {Skeleton} from "../ui/skeleton";


const Dashboard = ({page}) => {

    const [socialPosts, setSocialPosts] = useState([]);
    const [socialPostsLoading, setSocialPostsLoading] = useState(false);

    useEffect(() => {
        setSocialPostsLoading(true);
        fetch(`/pages/${page.id}/social_posts.json${window.location.search}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
            }
        })
            .then(response => response.json())
            .then(data => {
                setSocialPosts(data);
                setSocialPostsLoading(false);
            });
    }, []);

    const onNewPostClick = () => {
        // window.location.href = `/pages/${page.id}/new_social_post_and_save`;
        Turbo.visit(`pages/${page.id}/new_social_post_and_save`);
    }

    return (
        <div className="mt-4">
            <div className="flex justify-end items-end lg:pr-8">
                <PrimaryButton onClick={onNewPostClick} className="inline-block justify-end" text="New Post"/>
            </div>

            {socialPostsLoading ?
                <div className="grid grid-cols-1 lg:grid-cols-4 gap-4 mt-8">
                    <Skeleton className={`p-4 max-h-96 h-96 min-h-96`}></Skeleton>
                    <Skeleton className={`p-4 max-h-96 h-96 min-h-96`}></Skeleton>
                    <Skeleton className={`p-4 max-h-96 h-96 min-h-96`}></Skeleton>
                    <Skeleton className={`p-4 max-h-96 h-96 min-h-96`}></Skeleton>
                </div> :
                <div className="grid grid-cols-1 lg:grid-cols-4 gap-4 mt-8">
                    {socialPostsLoading && <div>Loading...</div>}
                    {socialPosts.map((socialPost, index) => (
                        <SocialPostCard page={page} key={socialPost.id}
                                        setSocialPost={(data, index) => {
                                            const newPosts = [...socialPosts];
                                            newPosts[index] = data;
                                            setSocialPosts(newPosts);
                                        }}
                                        index={index}
                                        socialPost={socialPost}/>
                    ))}
                </div>
            }
        </div>
    )
}

Dashboard.propTypes = {
    page: PropTypes.object
};

export default Dashboard
