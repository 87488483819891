import React from "react";
import PropTypes from "prop-types";
import {
    Dialog,
    DialogTrigger,
    DialogContent,
    DialogHeader,
    DialogTitle,
    DialogDescription,
    DialogClose
} from "../ui/dialog";
import {Button} from "../ui/button";

const DisabledForFreeUsersModal = ({open, setOpen}) => {
    return (
        <Dialog open={open} onOpenChange={setOpen}>
            <DialogContent className="z-[1000]">
                <DialogHeader>
                    <DialogTitle>Upgrade</DialogTitle>
                    <DialogDescription>
                        New video creation is temporarily disabled for free users. Upgrade to create videos.
                    </DialogDescription>
                </DialogHeader>
                <div className="flex flex-col justify-center items-center">
                    <a className="mt-4 cursor-pointer text-white bg-primary tex-white uppercase font-bold rounded-lg text-sm px-5 py-2.5 text-center" href="/recents" target="_blank">
                        Show Recently Created Videos
                    </a>
                    <a className="mt-4 cursor-pointer text-white bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 uppercase font-bold rounded-lg text-sm px-5 py-2.5 text-center" href="/pricing" target="_blank" onClick={() => setOpen(false)}>
                        Upgrade
                    </a>
                </div>
            </DialogContent>
        </Dialog>
    );
};

DisabledForFreeUsersModal.propTypes = {
    open: PropTypes.bool,
    setOpen: PropTypes.func
};

export default DisabledForFreeUsersModal;
