import React, {Dispatch, useEffect, useMemo, useState} from "react"
import PropTypes from "prop-types"
import {Tabs, TabsContent, TabsList, TabsTrigger} from "../ui/tabs";
import PostTemplateList from "./PostTemplateList";
import {useCarousel} from "../../hooks/useCarousel";
import {ThemeSettingsView} from "./ThemeSettings";
import {SignatureSettings} from "./SignatureSettings";
import {CarouselData, CarouselSlide, TemplateSettings, ThemeSettings} from "../../types/carouselData";
import {ScrollArea, ScrollBar} from "../ui/scroll-area";
import {TemplateOne} from "../templates/TemplateOne";
import {TemplateTwo} from "../templates/TemplateTwo";
import themesData from "../../data/themes.json";
import {showJetToast} from "../Toast";
import jsPDF from 'jspdf';
import {Button} from "../ui/button";
import {Loader2} from "lucide-react";


const CarouselGenerator = ({carouselData, setCarouselData, templates, onDownloadClick, isDownloading, page, isPaidUser}) => {
    const initialSlides = [
        {title: "Slide 1", description: "Description 1"},
        {title: "Slide 2", description: "Description 2"},
    ];

    // Local state initialized from carouselData
    const [name, setName] = useState(carouselData.name || "");
    const [image_url, setImageUrl] = useState(carouselData.image_url || "");
    const [social_handle, setSocialHandle] = useState(carouselData.social_handle || "");
    const [title, setTitle] = useState(carouselData.data?.title);
    const [call_to_action_text, setCallToActionText] = useState(carouselData.call_to_action_text);
    const [call_to_action_button_text, setCallToActionButtonText] = useState(carouselData.call_to_action_button_text);
    const [slides, setSlides] = useState(carouselData.data?.slides || initialSlides);
    const [theme_settings, setThemeSettings] = useState(carouselData.theme_settings || themesData[6]);
    const [isCarouselExporting, setIsCarouselExporting] = useState(false);
    const width = "480x";
    const height = "600px";

    useEffect(() => {
        // update only if values change
        if (carouselData.name === name && carouselData.image_url === image_url && carouselData.social_handle === social_handle &&
            carouselData.call_to_action_text === call_to_action_text && carouselData.call_to_action_button_text === call_to_action_button_text &&
            carouselData.data?.title === title && carouselData.data?.slides === slides && carouselData.theme_settings === theme_settings) {
            return;
        }
        setCarouselData({
            title,
            name,
            image_url,
            social_handle,
            call_to_action_text,
            call_to_action_button_text,
            theme_settings,
            data: {
                title,
                slides,
            },
            template_settings: templates[carouselData.template_settings.template_name]
        });
    }, [name, image_url, social_handle, call_to_action_text,
        call_to_action_button_text, slides, theme_settings, carouselData, setCarouselData, title]);

    const setTemplateSettings = (template) => {
        switch (template.template_name) {
            case "Formal":
                setThemeSettings(themesData[5]);
                break;
            case "Android":
                setThemeSettings(themesData[0]);
                break;
            case "Modern":
                setThemeSettings(themesData[6]);
                break;
            case "Pink":
                setThemeSettings(themesData[7]);
                break;
            default:
                setThemeSettings(themesData[5]);
                break;
        }

        setCarouselData({
            ...carouselData,
            template_settings: templates[template.template_name]
        });
    }

    const onAddClick = (index) => {
        const newSlides = [...slides];
        newSlides.splice(index + 1, 0, {title: "Sample Title", description: "Sample Description"});
        setSlides(newSlides);
    }

    const onDeleteClick = (index) => {
        if (slides.length === 1) {
            showJetToast("At least one slide is required");
            return;
        }
        const newSlides = [...slides];
        newSlides.splice(index, 1);
        setSlides(newSlides);
    }

    const exportPdf = async () => {
        setIsCarouselExporting(true);

        const pdf = new jsPDF({
            orientation: 'portrait',
            unit: 'px',
            format: [480, 600],
        });

        const elements = document.querySelectorAll('.pdf-page');

        // remove all nodes with class icon
        elements.forEach((element) => {
            const icons = element.querySelectorAll('.icon');
            icons.forEach((icon) => {
                icon.remove();
            });
        });

        for (let i = 0; i < elements.length; i++) {
            if (i > 0) pdf.addPage();

            // @ts-ignore
            await pdf.html(elements[i], {
                width: 480,
                windowWidth: 480,
                x: 0,
                y: 480 * i,
                fontFaces: [
                    {
                        family: 'Poppins',
                        src: [
                            {
                                url: 'https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap',
                                format: 'woff2'
                            }
                        ]
                    },
                    {
                        family: 'Roboto',
                        src: [
                            {
                                url: 'https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap',
                                format: 'woff2'
                            }
                        ]
                    }
                ]
            });
        }

        // delete last page, hack to fix last blank page
        // @ts-ignore
        pdf.deletePage(pdf.internal.getNumberOfPages());
        const currentTime = new Date().toLocaleString();
        pdf.save(`carousel_${currentTime}.pdf`);

        setIsCarouselExporting(false);
    };

    const onTitleChange = (index, title) => {
        const newSlides = [...slides];
        newSlides[index].title = title;
        setSlides(newSlides);
    }

    const onCarouselTitleChange = (title: string) => {
        setTitle(title);
    }

    const onDescriptionChange = (index, description) => {
        const newSlides = [...slides];
        newSlides[index].description = description;
        setSlides(newSlides);
    }

    const onSignatureChange = (field) => (e) => {
        const value = e.target.value;
        switch (field) {
            case 'name':
                setName(value);
                break;
            case 'image_url':
                setImageUrl(value);
                break;
            case 'call_to_action_text':
                setCallToActionText(value);
                break;
            case 'call_to_action_button_text':
                setCallToActionButtonText(value);
                break;
            case 'social_handle':
                setSocialHandle(value);
                break
            default:
                break;
        }
    };


    if (!carouselData.data || !carouselData.data?.slides || !theme_settings) {
        return <div>Loading...</div>;
    }

    return (
        <div className="w-full">
            <Tabs defaultValue="design">
                <TabsList>
                    <TabsTrigger value="design">Design</TabsTrigger>
                    <TabsTrigger value="theme">Theme</TabsTrigger>
                    <TabsTrigger value="signature">Signature</TabsTrigger>
                </TabsList>

                <TabsContent value="design">
                    <Tabs
                        className="m-4"
                        defaultValue={carouselData?.template_settings?.template_name}
                        onValueChange={(value) => {
                            setTemplateSettings(templates[value])
                        }
                    }>
                        <TabsList>
                            {Object.keys(templates).map((template) => (
                                <TabsTrigger key={template} value={template}>
                                    {template}
                                </TabsTrigger>
                            ))}
                        </TabsList>
                    </Tabs>
                </TabsContent>

                <TabsContent value="theme">
                    <ThemeSettingsView theme={theme_settings} onThemeChange={setThemeSettings}/>
                </TabsContent>

                <TabsContent value="signature">
                    <SignatureSettings name={name} call_to_action_button_text={call_to_action_button_text}
                                       call_to_action_text={call_to_action_text} image_url={image_url}
                                       social_handle={social_handle}
                                       pageId={page.id}
                                       onSignatureChange={onSignatureChange}/>
                </TabsContent>
            </Tabs>
            <div className="text-sm text-gray-400 my-2">Pro Tip: You can edit the content in carousel.</div>
            <div className="carousel gap-1 flex flex-row w-full h-[600px] overflow-x-scroll">
                <div key={"start"} className={`shadow max-w-[${width}] w-[${width}] h-[${height}] aspect-[480/600]`}>
                    {carouselData.template_settings.renderSlide({
                        carouselData,
                        index: -1,
                        onDeleteClick,
                        onAddClick,
                        onCarouselTitleChange,
                        onTitleChange,
                        onDescriptionChange,
                        isPaidUser: isPaidUser
                    })}
                </div>
                {carouselData.data.slides?.map((slide, index) => (
                    <div key={index} className={`shadow  max-w-[${width}] w-[${width}] h-[${height}] aspect-[480/600]`}>
                        {carouselData.template_settings.renderSlide({
                            carouselData,
                            index,
                            onDeleteClick,
                            onAddClick,
                            onCarouselTitleChange,
                            onTitleChange,
                            onDescriptionChange,
                            isPaidUser: isPaidUser
                        })}
                    </div>
                ))}
                <div key={"end"} className={`shadow max-w-[${width}] w-[${width}] h-[${height}] aspect-[480/600]`}>
                    {carouselData.template_settings.renderSlide({
                        carouselData,
                        index: 999,
                        onDeleteClick,
                        onAddClick,
                        onCarouselTitleChange,
                        onTitleChange,
                        onDescriptionChange,
                        isPaidUser: isPaidUser
                    })}
                </div>
            </div>

            <Button disabled={isDownloading || isCarouselExporting} className="mt-2" onClick={onDownloadClick} variant="secondary">
                Download Carousel
                {(isCarouselExporting || isDownloading) ? <Loader2 size={16} className="ml-2 animate-spin"/> : <></>}
            </Button></div>
    );
};

CarouselGenerator.propTypes = {};

export default CarouselGenerator
