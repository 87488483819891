"use client"

import * as React from "react"
import {Check, ChevronsUpDown} from "lucide-react"
import {cn} from "../../lib/utils"
import {Button} from "../ui/button"
import {Popover, PopoverContent, PopoverTrigger} from "../ui/popover";
import {Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList} from "../ui/command";

export function SlidesRangeSelection({
                                         slidesRanges,
                                         onSlidesRangeSelected,
                                         selectedSlidesRange,
                                     }) {
    const [open, setOpen] = React.useState(false)

    const onOpenChange = (nextOpen) => {
        setOpen(nextOpen);
    }

    return (
        <Popover open={open} onOpenChange={onOpenChange}>
            <PopoverTrigger asChild>
                <Button
                    variant="outline"
                    role="combobox"
                    aria-expanded={open}
                    className="w-[300px] justify-between"
                >
                    {selectedSlidesRange ? selectedSlidesRange.range : "Select slides range"}
                    <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50"/>
                </Button>
            </PopoverTrigger>
            <PopoverContent className="w-[300px] p-0 h-[300px]" onOpenAutoFocus={(e) => e.preventDefault()}>
                <Command className="w-full">
                    <CommandInput
                        placeholder={"Search slides range..."}
                        className="focus:ring-0 focus:border-transparent border-none"/>
                    <CommandEmpty>No slides range found.</CommandEmpty>
                    <CommandList>
                        <CommandGroup>
                            {slidesRanges.map((range) => (
                                <CommandItem
                                    key={range.range}
                                    value={range.range}
                                    className={"cursor-pointer"}
                                    onSelect={(currentValue) => {
                                        const selectedRange = slidesRanges.find((r) => r.range === currentValue);
                                        onSlidesRangeSelected(selectedRange);
                                        setOpen(false);
                                    }}
                                >
                                    <div className="flex flex-row justify-between items-center w-full min-w-full">
                                        <div className="flex flex-row cursor-pointer items-center">
                                            <Check
                                                className={cn(
                                                    "mr-2 h-4 w-4",
                                                    selectedSlidesRange?.range === range.range ? "opacity-100" : "opacity-0"
                                                )}
                                            />
                                            <div>{range.range}</div>
                                        </div>
                                        {range.paid && (
                                            <div className="ml-2 text-red-500">Paid</div>
                                        )}
                                    </div>
                                </CommandItem>
                            ))}
                        </CommandGroup>
                    </CommandList>
                </Command>
            </PopoverContent>
        </Popover>
    )
}
