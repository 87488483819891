import { createConsumer, ActionCable } from '@rails/actioncable';

interface SubscriptionCallbacks {
    initialized?: () => void;
    connected?: () => void;
    disconnected?: () => void;
    received: (data: any) => void; // Adjust the type as needed
}

class WebSocketService {
    private cable: ActionCable.Consumer | null = null;
    private subscriptions: Map<string, ActionCable.Subscription> = new Map(); // Store subscriptions by channel ID

    connect() {
        const isProduction = window.location.hostname !== 'localhost';
        const protocol = isProduction ? 'wss' : 'ws';
        let cableUrl = `${protocol}://${window.location.hostname}:3000/cable`;
        if (isProduction) {
            cableUrl = `${protocol}://${window.location.hostname}/cable`;
        }
        this.cable = createConsumer(cableUrl);

        if (isProduction) {
            // this.cable.connection.logger.enabled = false;
        }
    }

    subscribe(channel: string, socialPostId: string, callbacks: SubscriptionCallbacks) {
        const key = `${channel}:${socialPostId}`;

        if (this.subscriptions.has(key)) {
            // remove previous subscription
            const subscription = this.subscriptions.get(key);
            if (subscription) {
                subscription.unsubscribe();
                this.subscriptions.delete(key);
            }
        }

        if (!this.subscriptions.has(key)) {
            const subscription = this.cable!.subscriptions.create(
                { channel, social_post_id: socialPostId },
                callbacks
            );
            this.subscriptions.set(key, subscription);
        }

        return this.subscriptions.get(key);
    }

    unsubscribe(channel: string, socialPostId: string) {
        const key = `${channel}:${socialPostId}`;
        const subscription = this.subscriptions.get(key);

        if (subscription) {
            subscription.unsubscribe();
            this.subscriptions.delete(key);
        }
    }
}

const websocketService = new WebSocketService();
websocketService.connect();
export default websocketService;
