import React, {useEffect, useRef, useState} from "react";
import PropTypes from "prop-types";
import {Edit, Trash2} from "lucide-react";
import {Button} from "../ui/button";
import {Turbo} from "@hotwired/turbo-rails";
import DeleteSocialPostModal from "./DeleteSocialPostModal";
import {showJetToast} from "../Toast";
import SocialPostStatus from "./SocialPostStatus";
import {formatLocalTime} from "../../lib/utils";

const SocialPostCard = ({socialPost, page, setSocialPost, index}) => {

    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const intervalRef = useRef(null); // To store the interval ID
    const isFetchingRef = useRef(false); // Track if a fetch is in progress
    const retryCount = useRef(0); // Initialize the counter with useRef

    let border = "border-dashed border-2 border-gray-400"
    switch (socialPost.status) {
        case "draft":
            border = "border-dashed border-2 border-gray-400"
            break;
        case "scheduled":
            border = "border-dashed border-2 border-amber-400"
            break;
        case "published":
            border = "border-2"
            break;
        case "deleted":
            border = "border-dashed border-2 border-red-400"
            break;
        default:
            border = "border-dashed border-2 border-gray-400"
    }

    useEffect(() => {
        if (socialPost.status === "publishing") {
            startPolling();
        }

        return () => {
            clearInterval(intervalRef.current); // Clean up interval
            retryCount.current = 0; // Reset retry count
        };
    }, []);

    const fetchSocialPost = async () => {
        try {
            if (retryCount.current > 4) {
                clearInterval(intervalRef.current); // Stop polling if retry count is more than 5
                return;
            }
            if (isFetchingRef.current) return;

            isFetchingRef.current = true; // Mark fetching as true
            const response = await fetch(`/pages/${page.id}/social_posts/${socialPost.id}.json`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    // @ts-ignore
                    'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content,
                },
            });
            if (response.ok) {
                const data = await response.json();
                if (data.status !== "publishing") {
                    clearInterval(intervalRef.current); // Stop polling if status is not publishing
                }
                if (data.last_updated_at != socialPost.last_updated_at) {
                    clearInterval(intervalRef.current);
                }
                setSocialPost(data, index);
                return data;
            } else {
                console.error('Failed to fetch social post');
            }
        } catch (error: any) {
            console.error('Error:', error.message);
        } finally {
            isFetchingRef.current = false; // Mark fetching as false after completion
            retryCount.current += 1; // Increment the retry count
        }
    }

    const startPolling = () => {
        if (!intervalRef.current) { // Only start a new interval if one doesn't exist
            intervalRef.current = setInterval(() => {
                fetchSocialPost(); // Fetch data every 30 seconds
            }, 20000); // 20 seconds
        }
    };

    const onEditClick = () => {
        // window.location.href = `/pages/${page.id}/social_posts/${socialPost.id}/edit`;
        Turbo.visit(`pages/${page.id}/social_posts/${socialPost.id}/edit`);
    }

    const onDeleteClick = () => {
        setShowDeleteModal(true);
    }

    const deleteSocialPost = async () => {
        try {
            const response = await fetch(`/pages/${page.id}/social_posts/${socialPost.id}.json`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    // @ts-ignore
                    'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content,
                },
            });
            if (response.ok) {
                Turbo.visit("/dashboard");
            } else {
                showJetToast("Failed to delete post!");
            }
        } catch (error: any) {
            console.error('Error:', error.message);
            showJetToast("Failed to delete post!");
        } finally {
            setShowDeleteModal(false);
        }
    }

    return (
        <div className={`p-4 cursor-pointer bg-white rounded-lg border ${border} shadow min-h-96`}>
            <div className="flex flex-row items-center justify-between">
                {socialPost.status != "deleted" ? <Button aria-label="Delete" variant="outline" onClick={(event) => {
                    event.stopPropagation();
                    onDeleteClick()
                }}>
                    <Trash2 className="w-5 h-5 text-gray-600 hover:text-gray-800"/>
                </Button> : null}
                <SocialPostStatus socialPost={socialPost} shares={null}/>
                {socialPost.status != "deleted" ?
                    <Button aria-label="Edit" variant="outline" onClick={() => onEditClick()}>
                        <Edit className="w-5 h-5 text-gray-600 hover:text-gray-800"/>
                    </Button> : null}
            </div>

            <div className=" font-semibold text-sm line-clamp-2 text-center flex-grow px-4 mt-2">
                {socialPost.title}
            </div>

            <div className="leading-5 line-clamp-[12] text-sm overflow-ellipsis mt-2 whitespace-pre-line"
                 onClick={() => onEditClick()}>{socialPost.content}</div>
            <div className="mt-4 text-xs text-textCaption w-full">Created at: {formatLocalTime(socialPost.created_at)}</div>
            <div className="text-xs text-textCaption w-full">Last Updated: {formatLocalTime(socialPost.updated_at)}</div>
            {socialPost.status === "scheduled" ? (
                <div className="bg-admin p-2 rounded my-1">
                    <div className="text-xs text-textCaption">Scheduled
                        at: {formatLocalTime(socialPost.scheduled_at)}</div>
                </div>
            ) : null}
            <DeleteSocialPostModal open={showDeleteModal} setOpen={setShowDeleteModal}
                                   onDeleteClick={deleteSocialPost}/>
        </div>
    );
};

SocialPostCard.propTypes = {
    socialPost: PropTypes.object.isRequired,
};

export default SocialPostCard;
