import React, {useState} from "react"

const WeekdayPicker = ({selectedDays, setSelectedDays}) => {
    return (
        <div className="flex flex-row gap-2">
            {["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"].map((day, index) => {
                return (
                    <div key={index} className={`px-2 py-1 rounded-md ${selectedDays.includes(index) ? "bg-primary text-white" : "bg-gray-200 text-gray-700"} cursor-pointer`} onClick={() => {
                        if (selectedDays.includes(index)) {
                            setSelectedDays(selectedDays.filter((day) => day !== index));
                        } else {
                            setSelectedDays([...selectedDays, index]);
                        }
                    }}>
                        {day}
                    </div>
                )
            })}
        </div>
    )
}

WeekdayPicker.propTypes = {
};

export default WeekdayPicker
