import React, { useState } from 'react';
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList } from "../ui/command";
import { Check, ChevronsUpDown } from "lucide-react";
import { Popover, PopoverContent, PopoverTrigger } from "../ui/popover";
import { Button } from "../ui/button";
import { cn } from "../../lib/utils";

type TimeSlot = { value: { start: number, end: number }; label: string };
type TimeZone = { value: string; label: string };

const timeSlots: TimeSlot[] = [
    { value: { start: 6, end: 9 }, label: '06:00 - 09:00' },
    { value: { start: 9, end: 12 }, label: '09:00 - 12:00' },
    { value: { start: 12, end: 15 }, label: '12:00 - 15:00' },
    { value: { start: 15, end: 18 }, label: '15:00 - 18:00' },
    { value: { start: 18, end: 21 }, label: '18:00 - 21:00' },
];

const TimeSlotSelector = ({
                              selectedTimeSlots,
                              setSelectedTimeSlots,
                              selectedTimeZone,
                              setSelectedTimeZone,
                              timeZones
                          }) => {
    const [open, setOpen] = useState(false);

    const onOpenChange = (nextOpen) => {
        setOpen(nextOpen);
    };

    const toggleTimeSlot = (slot) => {
        if (selectedTimeSlots.some(selectedSlot => selectedSlot.start === slot.start && selectedSlot.end === slot.end)) {
            setSelectedTimeSlots(selectedTimeSlots.filter(selectedSlot => selectedSlot.start !== slot.start || selectedSlot.end !== slot.end));
        } else {
            setSelectedTimeSlots([...selectedTimeSlots, slot]);
        }
    };

    return (
        <div className="flex flex-row gap-2 flex-wrap">
            {timeSlots.map((timeSlot, index) => (
                <div
                    key={index}
                    className={`px-2 py-1 rounded-md ${selectedTimeSlots.some(selectedSlot => selectedSlot.start === timeSlot.value.start && selectedSlot.end === timeSlot.value.end) ? "bg-primary text-white" : "bg-gray-200 text-gray-700"} cursor-pointer`}
                    onClick={() => toggleTimeSlot(timeSlot.value)}
                >
                    {timeSlot.label}
                </div>
            ))}

            <Popover open={open} onOpenChange={onOpenChange}>
                <PopoverTrigger asChild>
                    <Button
                        variant="outline"
                        role="combobox"
                        aria-expanded={open}
                        className="w-[300px] justify-between"
                    >
                        {selectedTimeZone ? selectedTimeZone.label : "Select Time Zone"}
                        <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50"/>
                    </Button>
                </PopoverTrigger>
                <PopoverContent className="w-[300px] p-0 h-[300px]" onOpenAutoFocus={(e) => e.preventDefault()}>
                    <Command className="w-full">
                        <CommandInput
                            placeholder="Search timezone..."
                            className="focus:ring-0 focus:border-transparent border-none"
                        />
                        <CommandEmpty>No Time Zone found.</CommandEmpty>
                        <CommandList>
                            <CommandGroup>
                                {timeZones.map((timeZone) => (
                                    <CommandItem
                                        key={timeZone.value}
                                        value={timeZone.value}
                                        className="cursor-pointer"
                                        onSelect={(currentValue) => {
                                            setSelectedTimeZone(timeZones.find((timeZone) => timeZone.value === currentValue));
                                            setOpen(false);
                                        }}
                                    >
                                        <div className="flex flex-row justify-between items-center w-full min-w-full">
                                            <div className="flex flex-row cursor-pointer items-center">
                                                <Check
                                                    className={cn(
                                                        "mr-2 h-4 w-4",
                                                        selectedTimeZone?.value === timeZone.value ? "opacity-100" : "opacity-0"
                                                    )}
                                                />
                                                <div>{timeZone.label}</div>
                                            </div>
                                        </div>
                                    </CommandItem>
                                ))}
                            </CommandGroup>
                        </CommandList>
                    </Command>
                </PopoverContent>
            </Popover>
        </div>
    );
};

export default TimeSlotSelector;
