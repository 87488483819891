import React, {Dispatch, SetStateAction} from "react";
import {ThemeSettings} from "../../types/carouselData";
import {Input} from "../ui/input";
import themesData from "../../data/themes.json";


interface ThemeSettingsProps {
    theme: ThemeSettings;
    onThemeChange: Dispatch<SetStateAction<ThemeSettings>>;
}

export const ThemeSettingsView: React.FC<ThemeSettingsProps> = ({theme, onThemeChange}) => {
    return (
        <div className="m-4 text-sm">
            <h2 className="text-lg font-bold">Predefined Themes</h2>
            <div className="flex flex-wrap gap-4 mt-2">
                {themesData.map((theme) => (
                    <div
                        key={theme.id}
                        className="flex flex-col gap-2"
                        onClick={() => onThemeChange(theme)}
                    >
                        <div
                            className="w-12 h-12 rounded-full"
                            style={{backgroundColor: theme.background_one_color}}
                        />
                        <span>{theme.name}</span>
                    </div>
                ))}
            </div>

            <div className="flex flex-col gap-4 mt-4">
                <div className="flex flex-wrap text-center justify-between gap-4">
                    <div className="flex flex-col">
                        <label>BG1</label>
                        <Input
                            type="color"
                            className="w-12 h-12 p-0"
                            value={theme.background_one_color}
                            onChange={(e) =>
                                onThemeChange({...theme, background_one_color: e.target.value})
                            }
                        />
                    </div>

                    <div className="flex flex-col">
                        <label>H1</label>
                        <Input
                            type="color"
                            className="w-12 h-12 p-0"
                            value={theme.heading_one_color}
                            onChange={(e) =>
                                onThemeChange({...theme, heading_one_color: e.target.value})
                            }
                        />
                    </div>

                    <div className="flex flex-col">
                        <label>BG Content</label>
                        <Input
                            type="color"
                            className="w-12 h-12 p-0"
                            value={theme.background_content_color}
                            onChange={(e) =>
                                onThemeChange({...theme, background_content_color: e.target.value})
                            }
                        />
                    </div>

                    <div className="flex flex-col">
                        <label>H Content</label>
                        <Input
                            type="color"
                            className="w-12 h-12 p-0"
                            value={theme.heading_content_color}
                            onChange={(e) =>
                                onThemeChange({...theme, heading_content_color: e.target.value})
                            }
                        />
                    </div>

                    <div className="flex flex-col">
                        <label>Content</label>
                        <Input
                            type="color"
                            className="w-12 h-12 p-0"
                            value={theme.content_color}
                            onChange={(e) =>
                                onThemeChange({...theme, content_color: e.target.value})
                            }
                        />
                    </div>
                </div>


            </div>
        </div>


    );
};
