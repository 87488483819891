import React, { useState } from 'react';
import { DirectUpload } from '@rails/activestorage';

const ImageUpload = ({ onUploadSuccess }) => {
    const [uploadProgress, setUploadProgress] = useState(null);
    const [uploadError, setUploadError] = useState(null);

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            uploadFile(file);
        }
    };

    const uploadFile = (file) => {
        setUploadProgress(0);
        setUploadError(null);

        const upload = new DirectUpload(
            file,
            '/rails/active_storage/direct_uploads' // Rails endpoint for direct uploads
        );

        upload.create((error, blob) => {
            if (error) {
                // Handle upload error
                setUploadError('Upload failed, please try again.');
                console.error(error);
            } else {
                // Upload successful, blob.signed_id contains the reference for the uploaded file
                const signedId = blob.signed_id;

                // Pass the signed_id to the parent component using the onUploadSuccess callback
                onUploadSuccess(signedId);

                // OPTIONAL: Create the URL for the uploaded file if you want to display it
                const uploadedFileUrl = `/rails/active_storage/blobs/${signedId}/${encodeURIComponent(file.name)}`;

                // Update progress to 100% after successful upload
                setUploadProgress(100);
            }
        }, (xhr) => {
            // Track the progress of the upload
            xhr.upload.addEventListener('progress', (event) => {
                if (event.lengthComputable) {
                    const progress = (event.loaded / event.total) * 100;
                    setUploadProgress(Math.round(progress));
                }
            });
        });
    };

    return (
        <div>
            <input
                type="file"
                accept="image/*"
                onChange={handleFileChange}
            />

            {uploadProgress !== null && (
                <div>
                    <p>Upload Progress: {uploadProgress}%</p>
                    {uploadProgress === 100 && <p>Upload complete!</p>}
                </div>
            )}

            {uploadError && <p className="error-message">{uploadError}</p>}
        </div>
    );
};

export default ImageUpload;
