import React, {useEffect, useState} from "react"
import PropTypes from "prop-types"

const SocialPostStatus = ({socialPost, shares}) => {

    let statusName = "Draft";
    switch (socialPost.status) {
        case "scheduled":
            statusName = "Scheduled";
            break;
        case "published":
            statusName = "Published";
            break;
        case "publishing":
            statusName = "Publishing";
            break;
        case "deleted":
            statusName = "Deleted";
            break;
        default:
            statusName = "Draft";
    }
    let statusStyle = "bg-gray-400 text-white focus:ring-2 focus:outline-none font-medium rounded-lg text-sm px-3 py-1 text-center inline-flex items-center";
    switch (socialPost.status) {
        case "scheduled":
            statusStyle = "bg-amber-500 text-white focus:ring-2 focus:outline-none font-medium rounded-lg text-sm px-3 py-1 text-center inline-flex items-center";
            break;
        case "published":
            statusStyle = "bg-green-500 text-white focus:ring-2 focus:outline-none font-medium rounded-lg text-sm px-3 py-1 text-center inline-flex items-center";
            break;
        case "publishing":
            statusStyle = "bg-green-700 text-white focus:ring-2 focus:outline-none font-medium rounded-lg text-sm px-3 py-1 text-center inline-flex items-center";
            break;
        case "deleted":
            statusStyle = "bg-red-500 text-white focus:ring-2 focus:outline-none font-medium rounded-lg text-sm px-3 py-1 text-center inline-flex items-center";
            break;
        default:
            statusStyle = "bg-gray-400 text-white focus:ring-2 focus:outline-none font-medium rounded-lg text-sm px-3 py-1 text-center inline-flex items-center";
    }

    return (
        <div className="flex flex-row justify-center items-center gap-2">
            <div
                className={statusStyle}
            >
                <span className="flex flex-row">{statusName}</span>
            </div>
            {(socialPost?.shares || shares)?.map((share: any, index: number) => (
                share?.shared && (
                    <a
                        key={index}
                        href={`https://www.linkedin.com/feed/update/${share["share_data"]["post_id"]}`} // replace with actual URL
                        target="_blank"
                        rel="noopener noreferrer"
                        className="h-6 w-auto text-primary"
                    >
                        <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24">
                            <path
                                d="M19 0h-14c-2.75 0-5 2.25-5 5v14c0 2.75 2.25 5 5 5h14c2.75 0 5-2.25 5-5v-14c0-2.75-2.25-5-5-5zm-11.25 19h-3v-9h3v9zm-1.5-10.28c-.97 0-1.75-.79-1.75-1.75s.78-1.75 1.75-1.75c.97 0 1.75.79 1.75 1.75s-.79 1.75-1.75 1.75zm12.75 10.28h-3v-4.5c0-1.07-.03-2.44-1.49-2.44-1.49 0-1.72 1.17-1.72 2.36v4.58h-3v-9h2.88v1.23h.04c.4-.76 1.39-1.56 2.86-1.56 3.06 0 3.63 2.02 3.63 4.65v4.68z"/>
                        </svg>
                    </a>
                )
            ))}
        </div>


    )

}

SocialPostStatus.propTypes = {};

export default SocialPostStatus
