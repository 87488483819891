import React, {useEffect, useState} from "react"
import PropTypes from "prop-types"
import {Select, SelectContent, SelectGroup, SelectItem, SelectLabel, SelectTrigger, SelectValue} from "../ui/select";
import PrimaryButton from "../common/PrimaryButton";
import {showJetToast} from "../Toast";
import { Turbo } from "@hotwired/turbo-rails";
import {Input} from "../ui/input";
import ImageUpload from "../common/ImageUpload";
import {Avatar, AvatarFallback, AvatarImage} from "../ui/avatar";

const CreatePage = ({actionName, page, root_url, imageUrlInitial}) => {
    const [pageTitle, setPageTitle] = useState<string>(page?.title || "");
    const [pageDescription, setPageDescription] = useState<string>(page?.description || "");
    const [isLoading, setIsLoading] = useState(false);
    const [visibility, setVisibility] = useState( page?.visibility || 'public' );
    const [pageId, setPageId] = useState(page?.id);
    const [name, setName] = useState(page?.signature_settings?.name || "");
    const [call_to_action_text, setCallToActionText] = useState(page?.signature_settings?.call_to_action_text || "");
    const [call_to_action_button_text, setCallToActionButtonText] = useState(page?.signature_settings?.call_to_action_button_text || "");
    const [social_handle, setSocialHandle] = useState(page?.signature_settings?.social_handle || "");
    const [imageUrl, setImageUrl] = useState(imageUrlInitial);

    const isEnabled = pageTitle && pageTitle.length > 0;

    const createPage = async () => {
        setIsLoading(true);
        try {
            const response = await fetch('/pages.json', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    // @ts-ignore
                    'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content
                },
                body: JSON.stringify({
                    page: {
                        title: pageTitle,
                        description: pageDescription,
                        visibility: visibility,
                        signature_settings: {
                            name: name,
                            call_to_action_text: call_to_action_text,
                            call_to_action_button_text: call_to_action_button_text,
                            social_handle: social_handle
                    }
                }}),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const brand = await response.json();
            setPageId(brand.id);
            console.log('Page created');
            showJetToast('Page created successfully');
            Turbo.visit("/dashboard");
        } catch (error) {
            console.error('Error:', error);
            showJetToast('Error creating page. Please try again.');
        } finally {
            setIsLoading(false);
        }
    }

    const updatePage = async () => {
        setIsLoading(true);
        try {
            const response = await fetch(`/pages/${pageId}.json`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    // @ts-ignore
                    'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content
                },
                body: JSON.stringify({
                    page: {
                        title: pageTitle,
                        description: pageDescription,
                        visibility: visibility,
                        signature_settings: {
                            name: name,
                            call_to_action_text: call_to_action_text,
                            call_to_action_button_text: call_to_action_button_text,
                            social_handle: social_handle
                        }
                    }
                })
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const brand = await response.json();
            console.log('Page updated');
            showJetToast('Page updated successfully');
            window.location.href = '/settings';
        } catch (error) {
            console.error('Error:', error);
            showJetToast('Error updating page. Please try again.');
        } finally {
            setIsLoading(false);
        }
    }

    const onSignatureChange = (field) => (e) => {
        const value = e.target.value;
        switch (field) {
            case 'name':
                setName(value);
                break;
            case 'call_to_action_text':
                setCallToActionText(value);
                break;
            case 'call_to_action_button_text':
                setCallToActionButtonText(value);
                break;
            case 'social_handle':
                setSocialHandle(value);
                break
            default:
                break;
        }
    };

    const onImageUploadSuccess = async (signedBlobId: any) => {
        try {
            const response = await fetch(`/pages/${page.id}/attach_image`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    // @ts-ignore
                    'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content
                },
                body: JSON.stringify({ image: signedBlobId }),
            });
            console.log("Signed Blob ID", signedBlobId);
            if (!response.ok) {
                throw new Error('Error uploading image. Please try again.');
            }
            const data = await response.json();
            setImageUrl(data.image_url);
            showJetToast('Image uploaded successfully');
        } catch (error) {
            console.error('Error:', error);
            showJetToast('Error uploading image. Please try again.');
        }
    }


    return (
        <div className="mt-8 shadow rounded-lg bg-white py-4 px-4">
            {actionName == "new" ? <h1 className="font-bold text-4xl">Create your page</h1> :
                <h1 className="font-bold text-4xl">Edit your page</h1>}
            <div className="my-5">
                <label htmlFor="brand_title">Page Name <span className="text-sm"> (required)</span></label>

                <input className="block shadow rounded-md border border-gray-200 outline-none px-3 py-2 mt-2 w-full"
                       type="text" name="brand[title]" id="brand_title" value={pageTitle}
                       placeholder={"My Personal Page"}
                       onChange={(e) => setPageTitle(e.target.value)}/>
            </div>
            <div className="my-5">
                <label htmlFor="brand_description">Description</label>
                <textarea rows={4}
                          value={pageDescription}
                          placeholder={"This is my personal LinkedIn page"}
                          onChange={(e) => setPageDescription(e.target.value)}
                          className=" block shadow rounded-md border border-gray-200 outline-none px-3 py-2 mt-2 w-full"
                          name="brand[description]" id="brand_description"></textarea>
            </div>
            <div className="h-[2px] bg-gray-200 my-4"/>
            <div className="my-4 font-bold text-lg">
                Signature Settings <span className="text-sm font-normal">(Optional)</span>
            </div>
            <div className="flex flex-col gap-4 m-4">
                <label>Name</label>
                <div className="text-sm m-0 p-0">Brand or Account name. This will be used in carousel posts.</div>
                <Input
                    type="text"
                    value={name}
                    onChange={onSignatureChange('name')}
                />

                <label>Image</label>
                {imageUrl ? <Avatar className="w-14 h-14 border">
                    <AvatarImage src={imageUrl}/>
                    <AvatarFallback>CN</AvatarFallback>
                </Avatar> : null}
                <ImageUpload onUploadSuccess={onImageUploadSuccess}/>

                <label>Call to Action Text</label>
                <Input
                    type="text"
                    value={call_to_action_text}
                    onChange={onSignatureChange('call_to_action_text')}
                />

                <label>Call to Action Button Text</label>
                <Input
                    type="text"
                    value={call_to_action_button_text}
                    onChange={onSignatureChange('call_to_action_button_text')}
                />
                <label>Social Handle</label>
                <Input
                    type="text"
                    value={social_handle}
                    onChange={onSignatureChange('social_handle')}
                />
            </div>

            {/*<div>Select Visibility for content on this page. This will be used for different social platforms that support setting visibility of content when posted.</div>*/}
            {/*<div className="my-4">*/}
            {/*    <Select*/}
            {/*        value={visibility}*/}
            {/*        onValueChange={(value) => setVisibility(value)}>*/}
            {/*        <SelectTrigger className="w-full">*/}
            {/*            <SelectValue placeholder="Select Content Visibility"/>*/}
            {/*        </SelectTrigger>*/}
            {/*        <SelectContent>*/}
            {/*            <SelectGroup>*/}
            {/*                <SelectLabel>Content Visibility on Social Platforms</SelectLabel>*/}
            {/*                <SelectItem key={"public"} value={"public"}><span>public</span></SelectItem>*/}
            {/*                <SelectItem key={"private"} value={"private"}><span>private</span></SelectItem>*/}
            {/*            </SelectGroup>*/}
            {/*        </SelectContent>*/}
            {/*    </Select>*/}
            {/*</div>*/}
            {actionName == "new" ?
                <PrimaryButton text="Create Page" disabled={!isEnabled} isLoading={isLoading} onClick={createPage}/> :
                <PrimaryButton text="Update Page" disabled={!isEnabled} isLoading={isLoading} onClick={updatePage}/>
            }
        </div>
    )
}

CreatePage.propTypes = {};

export default CreatePage
