import React from "react";
import PropTypes from "prop-types";
import {Avatar, AvatarFallback, AvatarImage} from "../ui/avatar";

const LinkedinOrganization = ({organization, handleAccountClick, selected}) => {

    function getAvatarName(input: string) {
        const words = input.split(" ");

        if (words.length === 1) {
            return words[0].slice(0, 2).toUpperCase();
        } else {
            const firstLetter = words[0][0];
            const lastLetter = words[words.length - 1][0];
            return (firstLetter + lastLetter).toUpperCase();
        }
    }

    return (
        <div onClick={() => handleAccountClick(organization.id)}
             className={`${selected ? "border border-1 border-primary" : ""} cursor-pointer flex flex-col items-center justify-center p-4 bg-white rounded-lg shadow-md`}>
            <Avatar className="w-20 h-20 rounded-full mb-">
                <AvatarImage src={organization["picture"]}/>
                <AvatarFallback>{getAvatarName(organization["localizedName"])}</AvatarFallback>
            </Avatar>
            <div className="text-center">
                <span className="text-lg font-semibold">{organization["localizedName"]}</span>
                <p className="text-sm text-gray-500">ID: {organization["id"]}</p>
            </div>
        </div>
    );
}

LinkedinOrganization.propTypes = {
    account: PropTypes.object,
    handleAccountClick: PropTypes.func,
    selected: PropTypes.bool
};

export default LinkedinOrganization;