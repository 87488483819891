import React, {useEffect, useMemo, useState} from "react"
import PropTypes from "prop-types"
import {TemplateOne} from "../templates/TemplateOne";
import {TemplateTwo} from "../templates/TemplateTwo";
import themesData from "../../data/themes.json";
import {TemplateModern} from "../templates/TemplateModern";
import {TemplatePink} from "../templates/TemplatePink";

const SocialPostPdf = ({carouselDataOriginal, linkedinData, pageImageUrl, page, isPaidUser}) => {

    const templates = useMemo(() => ({
        Modern: {template_name: "Modern", renderSlide: TemplateModern},
        Android: {template_name: "Android", renderSlide: TemplateOne},
        Formal: {template_name: "Formal", renderSlide: TemplateTwo},
        Pink: {template_name: "Pink", renderSlide: TemplatePink},
    }), []);

    const getCarouselData = (newCarouselData: any) => {
        return {
            ...newCarouselData,
            name: newCarouselData?.name || page.signature_settings?.name || linkedinData?.["name"],
            image_url: newCarouselData?.image_url || pageImageUrl || linkedinData?.["picture"],
            call_to_action_text: newCarouselData?.call_to_action_text || page.signature_settings?.call_to_action_text || "Liked the content?",
            call_to_action_button_text: newCarouselData?.call_to_action_button_text || page.signature_settings?.call_to_action_button_text || "Follow for more",
            data: newCarouselData?.data || {
                title: "Carousel Title",
                slides: [
                    {title: "Slide 1", description: "Description 1"},
                    {title: "Slide 2", description: "Description 2"},
                ]
            },
            theme_settings: newCarouselData?.theme_settings || themesData[6],
            template_settings: templates[newCarouselData?.template_settings?.template_name || "Modern"],
        };
    };

    const [carouselData, setCarouselData] = useState(getCarouselData(carouselDataOriginal));

    const width = "1200px";
    const height = "1500px";

    const template_settings = templates[carouselData.template_settings?.template_name || "Modern"];

    return (
        <div className="flex flex-col">
            {template_settings.renderSlide({
                carouselData,
                index: -1,
                height,
                width,
                isPaidUser,
            })}
            {carouselData.data.slides?.map((slide, index) => (
                template_settings.renderSlide({
                    carouselData,
                    index,
                    height,
                    width,
                    hideIcons: true,
                    isPaidUser,
                })
            ))}
            {template_settings.renderSlide({
                carouselData,
                index: 999,
                height,
                width,
                isPaidUser,
            })}
        </div>
    )
}

SocialPostPdf.propTypes = {};

export default SocialPostPdf
