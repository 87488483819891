import React, {useEffect, useState} from "react"
import PropTypes from "prop-types"
import {Skeleton} from "../ui/skeleton";

const CarouselPlaceholder = ({carouselData}) => {
    return (
        <div className="flex flex-row w-full h-[600px] overflow-x-scroll gap-2 m-4">
            <div
                style={{backgroundColor: carouselData?.theme_settings?.background_content_color}}
                className={`h-[600px] min-w-[480px] w-[480px] animate-pulse`}></div>
            <div
                style={{backgroundColor: carouselData?.theme_settings?.background_content_color}}
                className={`h-[600px] min-w-[480px] w-[480px] animate-pulse`}></div>
            <div
                style={{backgroundColor: carouselData?.theme_settings?.background_content_color}}
                className={`h-[600px] min-w-[480px] w-[480px] animate-pulse`}></div>
            <div
                style={{backgroundColor: carouselData?.theme_settings?.background_content_color}}
                className={`h-[600px] min-w-[480px] w-[480px] animate-pulse`}></div>
        </div>
    )
}

CarouselPlaceholder.propTypes = {};

export default CarouselPlaceholder
