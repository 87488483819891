import React, {useEffect, useMemo, useRef, useState} from "react"
import PropTypes from "prop-types"
import {Popover, PopoverContent, PopoverTrigger} from "../ui/popover";
import {ChevronLeft, ChevronRight, Info, Loader2} from "lucide-react";
import {LanguageSelection} from "../common/LanguageSelection";
import {Select, SelectContent, SelectGroup, SelectItem, SelectLabel, SelectTrigger, SelectValue} from "../ui/select";
import CreateSocialPostFromInput from "./CreateSocialPostFromInput";
import {Button} from "../ui/button";
import PostTemplateSelectionModal from "./PostTemplateSelectionModal";
import {showJetToast} from "../Toast";
import {matchYoutubeUrl} from "../../lib/utils";
import {
    AlertDialog, AlertDialogAction, AlertDialogCancel,
    AlertDialogContent, AlertDialogDescription, AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
    AlertDialogTrigger
} from "../ui/alert-dialog";
import {Checkbox} from "../ui/checkbox";
import {Accordion, AccordionContent, AccordionItem, AccordionTrigger} from "../ui/accordion";
import CarouselGenerator from "./CarouselGenerator";
import themesData from "../../data/themes.json";
import {TemplateOne} from "../templates/TemplateOne";
import {TemplateTwo} from "../templates/TemplateTwo";
import CarouselPlaceholder from "./CarouselPlaceholder";
import websocketService from '../websocketService';
import { Turbo } from "@hotwired/turbo-rails";

import ContentEditable from "../common/ContentEditable";
import {DateTimePicker} from "../ui/date-time-picker/date-time-picker";
import {DateValue} from "react-aria";
import {fromDate, getLocalTimeZone} from "@internationalized/date";
import {TemplateModern} from "../templates/TemplateModern";
import {TemplatePink} from "../templates/TemplatePink";
import SocialPostStatus from "./SocialPostStatus";



const CreateSocialPost = ({languages, socialPostOriginal, isPaidUser, linkedinData, page, postTemplate, pageImageUrl, shares, user}) => {

    const [socialPost, setSocialPost] = useState(socialPostOriginal);
    const [language, setLanguage] = useState(languages.find((language) => language.code === (socialPost.language || 'en')));
    const [type, setType] = useState(socialPost.input_type || "Topic");
    const [showTemplateSelectionModal, setShowTemplateSelectionModal] = useState(false);
    const [selectedPostTemplate, setSelectedPostTemplate] = useState(postTemplate || null);
    const [content, setContent] = useState(socialPost.content || "");
    const [typeValue, setTypeValue] = useState(socialPost.input_type_value || "");
    const [generatePostLoading, setGeneratePostLoading] = useState(socialPost?.status == "queued" || false);
    const [polling, setPolling] = useState(false);
    const [error, setError] = useState(null);
    const [publishLoading, setPublishLoading] = useState(false);
    const [showPublishConfirmationModal, setShowPublishConfirmationModal] = useState(false);
    const [isSaveLoading, setIsSaveLoading] = useState(false);
    const [generateCarousel, setGenerateCarousel] = useState(socialPost.should_post_carousel ? true : false);
    const [inputData, setInputData] = useState(socialPost.input_data || {});
    const [isDownloadLoading, setIsDownloadLoading] = useState(false);
    const [scheduleConfirmOpen, setScheduleConfirmOpen] = useState(false);
    const [date, setDate] = React.useState<DateValue>(
        fromDate(new Date(), getLocalTimeZone()).add({minutes: 60})
    );
    const [scheduleLoading, setScheduleLoading] = React.useState(false);

    const onDateChange = (date: DateValue) => {
        setDate(date);
    }

    const isValidDate = (date: DateValue) => {
        return date.toDate(getLocalTimeZone()) > new Date();
    }

    // Memoize templates to prevent recreation on every render
    const templates = useMemo(() => ({
        Modern: {template_name: "Modern", renderSlide: TemplateModern},
        Formal: {template_name: "Formal", renderSlide: TemplateTwo},
        Android: {template_name: "Android", renderSlide: TemplateOne},
        Pink: {template_name: "Pink", renderSlide: TemplatePink},
    }), []);
    const getCarouselData = (newCarouselData: any) => {
        return {
            ...newCarouselData,
            name: newCarouselData?.name || page.signature_settings?.name || linkedinData?.["name"] || user.full_name || "Sample Name",
            image_url: newCarouselData?.image_url || pageImageUrl || linkedinData?.["picture"] || user.avatar_url,
            call_to_action_text: newCarouselData?.call_to_action_text || page?.signature_settings?.call_to_action_text || "Liked the content?",
            call_to_action_button_text: newCarouselData?.call_to_action_button_text || page?.signature_settings?.call_to_action_button_text || "Follow for more",
            data: newCarouselData?.data || {
                title: "Sample Title",
                slides: [
                    {title: "Slide 1", description: "Description 1"},
                    {title: "Slide 2", description: "Description 2"},
                ]
            },
            theme_settings: newCarouselData?.theme_settings || themesData[6],
            template_settings: templates[newCarouselData?.template_settings?.template_name || "Modern"],
        };
    };

    const [carouselData, setCarouselData] = useState(getCarouselData(socialPost?.generation_data?.carouselData));

    const setSocialPostRef = useRef(setSocialPost);
    const setContentRef = useRef(setContent);
    const setCarouselDataRef = useRef(setCarouselData);
    const setGeneratePostLoadingRef = useRef(setGeneratePostLoading);

    useEffect(() => {
        setSocialPostRef.current = setSocialPost;
    }, [setSocialPost]);

    useEffect(() => {
        setContentRef.current = setContent;
    }, [setContent]);

    useEffect(() => {
        setCarouselDataRef.current = setCarouselData;
    }, [setCarouselData]);

    useEffect(() => {
        setGeneratePostLoadingRef.current = setGeneratePostLoading;
    }, [setGeneratePostLoading]);

    const handleDownload = async (fileUrl: string) => {
        // Extract the filename from the URL
        const filename = fileUrl.split('/').pop(); // Get the last segment of the URL as the filename

        try {
            setIsDownloadLoading(true);
            // Fetch the file
            const response = await fetch(fileUrl);
            if (!response.ok) {
                throw new Error('Network response was not ok.');
            }

            // Convert the response to a Blob
            const blob = await response.blob();

            // Create a URL for the Blob
            const url = window.URL.createObjectURL(blob);

            // Create a temporary anchor element
            const a = document.createElement('a');
            a.href = url;
            a.download = filename || 'download'; // Specify the filename for the downloaded file
            a.style.display = 'none'; // Hide the anchor element

            // Append the anchor to the body
            document.body.appendChild(a);

            // Trigger a click event on the anchor
            a.click();

            // Clean up
            window.URL.revokeObjectURL(url); // Free up memory
            document.body.removeChild(a);
            setIsDownloadLoading(false);
        } catch (error) {
            console.error('Error downloading file:', error);
            showJetToast('Failed to download file.');
        } finally {
            setIsDownloadLoading(false);
        }
    };
    useEffect(() => {
        const callbacks = {
            initialized() {
                // console.log("Subscription initialized");
            },
            connected() {
                // console.log("Connected to WebSocket");
            },
            disconnected() {
                // console.log("Disconnected from WebSocket");
            },
            received(data) {
                // Handle incoming message
                if (data && data.pdf_url) {
                    handleDownload(data.pdf_url);
                } else if (data && data.social_post) {
                    setSocialPostRef.current(data.social_post);
                    setCarouselDataRef.current(getCarouselData(data.social_post.generation_data?.carouselData));
                    setContentRef.current(data.social_post.content);
                    setGeneratePostLoadingRef.current(false);
                    showJetToast(data.message);
                    if (data.page_owner) {
                        // update div with id "credits" to data.credits
                        document.getElementById("credits").innerText = data.credits;
                    }
                } else if (data && data.error) {
                    showJetToast(data.error);
                    setGeneratePostLoadingRef.current(false);
                }
            },
        };

        // Subscribe to the channel using the WebSocket service
        const subscription = websocketService.subscribe("MessagesChannel", socialPost.id, callbacks);

        // Cleanup the subscription when component unmounts
        return () => {
            websocketService.unsubscribe("MessagesChannel", socialPost.id);
        };
    }, [socialPost.id]);


    const validatePost = () => {
        if (!typeValue) {
            showJetToast(`${type} cannot be empty!`);
            return false;
        }

        if (typeValue.length > 5000) {
            showJetToast("Input cannot be more than 5000 characters!");
        }

        switch (type) {
            case "Youtube":
                showJetToast(`${type} Support Coming Soon!`);
                return false;
        }

        switch (type) {
            case "Link":
                if (!typeValue.startsWith("http")) {
                    showJetToast("Invalid URL!");
                    return false;
                }
                break;
            case "Youtube":
                if (!matchYoutubeUrl(typeValue)) {
                    showJetToast("Invalid Youtube URL!");
                    return false;
                }
                break;
            case "Topic":
                if (typeValue.startsWith("http")) {
                    showJetToast("You have selected Topic generation but provided a URL");
                    return false;
                }
                break;
            case "Text":
                break;

        }
        return true;
    }

    const onDownloadClick = async () => {
        try {
            const response = await fetch(`/pages/${page.id}/social_posts/${socialPost.id}/download_pdf`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
                },
                body: JSON.stringify({
                    content: content,
                    input_type: type,
                    input_type_value: typeValue,
                    input_data: inputData,
                    language: language.code,
                    post_template_id: selectedPostTemplate ? selectedPostTemplate.id : null,
                    generation_data: {
                        carouselData: {...carouselData,
                            template_settings: { template_name: carouselData.template_settings.template_name}}
                    },
                    should_post_carousel: generateCarousel
                })
            });
            const data = await response.json();
            showJetToast("Your post will be downloaded shortly!");
            setIsDownloadLoading(true);
        } catch (error: any) {
            showJetToast("Failed to download post!");
            setIsDownloadLoading(false);
        }
    }

    const onGeneratePostClick = async () => {
        if (generatePostLoading || polling) {
            return;
        }
        if (!validatePost()) {
            return;
        }
        if (socialPost?.status == "published") {
            showJetToast("Post is already published!");
            return;
        }

        setGeneratePostLoading(true);
        try {
            const response = await fetch(`/pages/${page.id}/social_posts/${socialPost.id}/generate_post`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
                },
                body: JSON.stringify({
                    content: content,
                    input_type: type,
                    input_type_value: typeValue,
                    input_data: inputData,
                    language: language.code,
                    post_template_id: selectedPostTemplate ? selectedPostTemplate.id : null,
                    generation_data: {
                        carouselData: {...carouselData,
                            template_settings: { template_name: carouselData.template_settings.template_name}}
                    },
                    should_post_carousel: generateCarousel
                })
            });

            const data = await response.json();
            if (data.error) {
                showJetToast(data.error);
                setGeneratePostLoading(false);
                return;
            }
        } catch (error: any) {
            console.error("Error generating post", error);
            setGeneratePostLoading(false);
        }
    }

    const handleCarouselChange = (checked: boolean) => {
        setGenerateCarousel(checked);
    }

    const publishPost = async () => {
        if (!linkedinData) {
            showJetToast("Please connect your LinkedIn account first!");
            return;
        }

        if (!content) {
            showJetToast("Post content cannot be empty!");
            return;
        }

        if (content.length > 3000) {
            showJetToast("Post content cannot be more than 3000 characters!");
            return;
        }

        try {
            setPublishLoading(true);
            const response = await fetch(`/pages/${page.id}/social_posts/${socialPost.id}/publish`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
                },
                body: JSON.stringify({
                    content: content,
                    input_type: type,
                    input_type_value: typeValue,
                    input_data: inputData,
                    language: language.code,
                    post_template_id: selectedPostTemplate ? selectedPostTemplate.id : null,
                    generation_data: {
                        carouselData: {...carouselData,
                            template_settings: { template_name: carouselData.template_settings.template_name}}
                    },
                    should_post_carousel: generateCarousel
                })
            });
            showJetToast("Your post will be published to LinkedIn shortly!");
            // navigate after 1 seconds
            setTimeout(() => {
                Turbo.visit(`/dashboard`);
            }, 1000);
        } catch (error: any) {
            showJetToast("Failed to publish post!");
        } finally {
            setPublishLoading(false);
            setShowPublishConfirmationModal(false);
        }
    }

    const schedulePost = async () => {
        if (date && isValidDate(date)) {
            setScheduleLoading(true);
            const response = await fetch(`/pages/${page.id}/social_posts/${socialPost.id}/schedule`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
                },
                body: JSON.stringify({
                    content: content,
                    input_type: type,
                    input_type_value: typeValue,
                    input_data: inputData,
                    language: language.code,
                    post_template_id: selectedPostTemplate ? selectedPostTemplate.id : null,
                    generation_data: {
                        carouselData: {...carouselData,
                            template_settings: { template_name: carouselData.template_settings.template_name}}
                    },
                    should_post_carousel: generateCarousel,
                    scheduled_at: date.toDate("UTC")
                })
            });
            showJetToast("Your post is scheduled successfully!");
            // navigate after 1 seconds
            setTimeout(() => {
                Turbo.visit(`/dashboard`);
            }, 1000);
        } else {
            showJetToast('Please select a valid date and time');
        }
    }

    const onCopyClick = async () => {
        try {
            await navigator.clipboard.writeText(content);
            showJetToast("Post copied to clipboard!");
        } catch (error: any) {
            showJetToast("Failed to copy post!");
        }
    }

    const onSaveClick = async () => {
        try {
            setIsSaveLoading(true);
            const response = await fetch(`/pages/${page.id}/social_posts/${socialPost.id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
                },
                body: JSON.stringify({
                    content: content,
                    input_type: type,
                    input_type_value: typeValue,
                    input_data: inputData,
                    language: language.code,
                    post_template_id: selectedPostTemplate ? selectedPostTemplate.id : null,
                    generation_data: {
                        carouselData: {...carouselData,
                            template_settings: { template_name: carouselData.template_settings.template_name}}
                    },
                    should_post_carousel: generateCarousel
                })
            });
            showJetToast("Post saved successfully!");
        } catch (error: any) {
            showJetToast("Failed to save post!");
        } finally {
            setIsSaveLoading(false);
        }
    }

    return (
        <div className="mt-4 mx-2 max-w-3xl p-2">
            <div className="flex flex-row gap-4 items-center">
                <h1 className="text-2xl font-bold">Create Post</h1>
                <div className="flex flex-row gap-4">
                    <Popover>
                        <PopoverContent side={"right"} className={"max-w-5xl"}>The language your LinkedIn post will be
                            in.</PopoverContent>
                        <PopoverTrigger>
                            <Info size={14}/>
                        </PopoverTrigger>
                    </Popover>
                    <LanguageSelection
                        languages={languages}
                        selectedLanguage={language}
                        onLanguageSelected={(language) => setLanguage(language)}
                    />
                </div>
            </div>
            <div className="mt-5">
                <div className="my-4 flex flex-row justify-between items-center">
                    <div>Create a LinkedIn post from {type}</div>
                    <Select
                        value={type}
                        onValueChange={(value) => setType(value)}>
                        <SelectTrigger
                            className={"w-48"}>
                            <SelectValue placeholder="Create LinkedIn Post from"/>
                        </SelectTrigger>
                        <SelectContent>
                            <SelectGroup>
                                <SelectLabel>Create LinkedIn Post from</SelectLabel>
                                <SelectItem key={"Topic"} value={"Topic"}><span>Topic</span></SelectItem>
                                <SelectItem key={"Link"} value={"Link"}><span>Link</span></SelectItem>
                                <SelectItem key={"Text"} value={"Text"}><span>Text</span></SelectItem>
                                <SelectItem key={"Youtube"} value={"Youtube"}><span>Youtube</span></SelectItem>
                            </SelectGroup>
                        </SelectContent>
                    </Select>
                </div>
                <div className="mt-4">
                    <CreateSocialPostFromInput
                        inputData={inputData}
                        setInputData={setInputData}
                        type={type} typeValue={typeValue} setTypeValue={setTypeValue}/>
                </div>

                {/*<div className="flex mb-4 justify-between flex-row items-center mt-4">*/}
                {/*    <label className="">*/}
                {/*        Select a format from templates*/}
                {/*    </label>*/}
                {/*    <Button onClick={() => {*/}
                {/*        setShowTemplateSelectionModal(true)*/}
                {/*    }} variant="outline">Select Template*/}
                {/*        <ChevronRight size={16}/>*/}
                {/*    </Button>*/}
                {/*</div>*/}
                <div className="flex flex-row gap-4 mt-4">
                    <div className="flex items-center space-x-2 bg-white shadow p-2 rounded">
                        <Checkbox id="terms" checked={generateCarousel} onCheckedChange={handleCarouselChange}/>
                        <label
                            htmlFor="terms"
                            className="cursor-pointer text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                        >
                            Add carousel
                        </label>
                    </div>
                    <Button onClick={onGeneratePostClick}>Generate Post
                        {generatePostLoading || polling ? <Loader2 size={16} className="ml-2 animate-spin"/> :
                            <ChevronRight className="ml-2" size={16}/>}
                    </Button>
                </div>
                {generateCarousel ? <Accordion collapsible type="single" className="mt-2">
                    <AccordionItem value="item-1">
                        <AccordionTrigger className="font-bold text-primaryDark">Carousel</AccordionTrigger>
                        <AccordionContent>
                            {(generatePostLoading || polling) ?
                                <CarouselPlaceholder carouselData={carouselData}/> : null}
                            {(!generatePostLoading && !polling && carouselData.data) ?
                                <CarouselGenerator carouselData={carouselData}
                                                   onDownloadClick={onDownloadClick}
                                                   isDownloading={isDownloadLoading}
                                                   page={page}
                                                   isPaidUser={isPaidUser}
                                                   setCarouselData={setCarouselData} templates={templates}/> : null}
                        </AccordionContent>
                    </AccordionItem>
                </Accordion> : <></>}
                <div className="my-4 bg-white shadow rounded-lg border border-gray-300 px-3 py-2 ">
                    <div className="flex items-center space-x-4">
                        <img
                            src={carouselData.image_url || "https://via.placeholder.com/48"}
                            alt="Profile"
                            className="w-12 h-12 rounded-full"
                        />
                        <div>
                            <div className="text-sm font-bold">{linkedinData?.['name'] || user.full_name || 'Sample Name'} • You</div>
                            <div className="text-xs text-gray-500">

                            </div>
                            <div className="flex items-center space-x-1 text-xs text-gray-500">
                                <span>1m</span>
                                <span>•</span>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" data-supported-dps="16x16"
                                     fill="currentColor" className="mercado-match" width="16" height="16"
                                     focusable="false">
                                    <path
                                        d="M8 1a7 7 0 107 7 7 7 0 00-7-7zM3 8a5 5 0 011-3l.55.55A1.5 1.5 0 015 6.62v1.07a.75.75 0 00.22.53l.56.56a.75.75 0 00.53.22H7v.69a.75.75 0 00.22.53l.56.56a.75.75 0 01.22.53V13a5 5 0 01-5-5zm6.24 4.83l2-2.46a.75.75 0 00.09-.8l-.58-1.16A.76.76 0 0010 8H7v-.19a.51.51 0 01.28-.45l.38-.19a.74.74 0 01.68 0L9 7.5l.38-.7a1 1 0 00.12-.48v-.85a.78.78 0 01.21-.53l1.07-1.09a5 5 0 01-1.54 9z"></path>
                                </svg>
                            </div>
                        </div>
                    </div>

                    <ContentEditable
                        contentEditable="plaintext-only"
                        className="mt-4 w-full min-h-96 block whitespace-pre-line break-words outline-none focus:ring-0 focus:outline-none"
                        html={content}
                        disabled={socialPost?.status == "published"}
                        onChange={(e) => setContent(e.target.value)}
                    />
                    {content && content.length > 3000 ?<div className="text-end text-red-500">
                        {content.length}/3000
                    </div> : <></>}

                    <footer className="bg-white text-xs lg:text-sm rounded-lg mt-6">
                        <div className="flex justify-between items-center mb-4">
                            <div className="flex items-center space-x-2">
                                <div className="flex space-x-[1px]">
                                    <img className="w-6 h-6"
                                         src="https://storage.kolpost.com/data/linkedin_thumbs_up.svg"
                                         alt="like"/>
                                    <img className="w-6 h-6"
                                         src="https://storage.kolpost.com/data/linkedin_clap_hands.svg"
                                         alt="celebrate"/>
                                    <img className="w-6 h-6"
                                         src="https://storage.kolpost.com/data/linkedin_heart.svg"
                                         alt="love"/>
                                </div>
                                <span className="text-gray-600 text-sm hidden lg:inline-flex">
        Hamid and 78 others
      </span>
                            </div>

                            <div className="flex items-center space-x-2 text-gray-600 text-sm">
                                <button className="hover:underline">4 comments</button>
                                <span>•</span>
                                <button className="hover:underline">1 repost</button>
                            </div>
                        </div>

                        <div className="flex justify-around items-center border-t pt-4 pb-3">
                            <div className="flex items-center space-x-1 text-gray-600">
                                <svg aria-hidden="true" className="w-5 h-5" xmlns="http://www.w3.org/2000/svg"
                                     fill="none" stroke="currentColor" viewBox="0 0 26 26">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                          d="M22.75 10.563h-4.874v10.562h4.875a.812.812 0 0 0 .812-.813v-8.937a.812.812 0 0 0-.812-.813v0Z"></path>
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                          d="m17.876 10.563-4.063-8.126a3.25 3.25 0 0 0-3.25 3.25v2.438H4.28a1.625 1.625 0 0 0-1.613 1.827l1.22 9.75a1.625 1.625 0 0 0 1.612 1.423h12.378"></path>
                                </svg>
                                <span>Like</span>
                            </div>

                            <div className="flex items-center space-x-1 text-gray-600">
                                <svg aria-hidden="true" className="w-5 h-5" xmlns="http://www.w3.org/2000/svg"
                                     fill="none" stroke="currentColor" viewBox="0 0 26 26">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                          d="M5.28 17.976a9.746 9.746 0 1 1 3.41 3.41h0l-3.367.962a.813.813 0 0 1-1.005-1.004l.963-3.368h0ZM10.417 11.375h6.5M10.417 14.625h6.5"></path>
                                </svg>
                                <span>Comment</span>
                            </div>

                            <div className="flex items-center space-x-1 text-gray-600">
                                <svg aria-hidden="true" className="w-5 h-5" xmlns="http://www.w3.org/2000/svg"
                                     fill="none" stroke="currentColor" viewBox="0 0 26 26">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                          d="m18.208 15.438 4.875-4.876-4.875-4.874"></path>
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                          d="M3.583 20.313a9.75 9.75 0 0 1 9.75-9.75h9.75"></path>
                                </svg>
                                <span>Repost</span>
                            </div>

                            <div className="flex items-center space-x-1 text-gray-600">
                                <svg aria-hidden="true" className="w-5 h-5" xmlns="http://www.w3.org/2000/svg"
                                     fill="none" stroke="currentColor" viewBox="0 0 26 26">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                          d="M21.354 3.644 2.43 8.98a.812.812 0 0 0-.128 1.517l8.695 4.118c.17.08.306.217.387.387l4.118 8.695a.812.812 0 0 0 1.517-.128l5.337-18.924a.813.813 0 0 0-1.002-1.002ZM11.26 14.74l4.596-4.596"></path>
                                </svg>
                                <span>Send</span>
                            </div>
                        </div>
                    </footer>


                </div>
            </div>

            <div className="flex flex-row gap-4 justify-between">
                <div className="flex flex-row gap-2">
                    <Button onClick={onSaveClick} variant="secondary">
                    Save
                        {isSaveLoading ? <Loader2 size={16} className="ml-2 animate-spin"/> : <></>}
                    </Button>
                    <Button onClick={onCopyClick} variant="secondary">
                        Copy
                    </Button>
                </div>
            </div>
            {socialPost.status == "published" ? <SocialPostStatus shares={shares} socialPost={socialPost}/> : <div className="flex flex-col w-full">
                <div className="rounded relative top-4w-full p-4 mb-10">
                    <div className="flex gap-3 flex-col font-medium">
                        <div
                            className="text-lg flex  p-2 rounded cursor-pointer items-center justify-center">
                            <AlertDialog open={showPublishConfirmationModal}
                                         onOpenChange={setShowPublishConfirmationModal}>
                                <AlertDialogTrigger asChild>
                                    <Button>
                                        Publish to LinkedIn
                                    </Button>
                                </AlertDialogTrigger>
                                <AlertDialogContent>
                                    <AlertDialogHeader>
                                        <AlertDialogTitle>Publish to LinkedIn</AlertDialogTitle>
                                        <AlertDialogDescription>
                                            This will post your post on LinkedIn. Post Now?
                                        </AlertDialogDescription>
                                    </AlertDialogHeader>
                                    <AlertDialogFooter>
                                        <AlertDialogCancel>Cancel</AlertDialogCancel>
                                        <AlertDialogAction onClick={publishPost}>
                                            <div>Publish Now</div>
                                        </AlertDialogAction>
                                    </AlertDialogFooter>
                                </AlertDialogContent>
                            </AlertDialog>
                        </div>
                        <div className="text-md text-textCaption">Schedule for Later</div>
                        <DateTimePicker granularity={"minute"} onChange={onDateChange} value={date}/>
                        <div
                            className="text-lg flex justify-center items-center p-2 rounded cursor-pointer">
                            <AlertDialog open={scheduleConfirmOpen} onOpenChange={setScheduleConfirmOpen}>
                                <AlertDialogTrigger asChild>
                                    <Button variant="outline">Schedule</Button>
                                </AlertDialogTrigger>
                                <AlertDialogContent>
                                    <AlertDialogHeader>
                                        <AlertDialogTitle>Schedule for later?</AlertDialogTitle>
                                        <AlertDialogDescription>
                                            This will schedule the post to be published at the specified time. Are you
                                            sure
                                            you want to schedule the post?
                                            <div className="mt-4">You can edit the post anytime before it is
                                                published.</div>
                                        </AlertDialogDescription>
                                    </AlertDialogHeader>
                                    <AlertDialogFooter>
                                        <AlertDialogCancel>Cancel</AlertDialogCancel>
                                        <AlertDialogAction onClick={schedulePost}>
                                            Schedule
                                        </AlertDialogAction>
                                    </AlertDialogFooter>
                                </AlertDialogContent>
                            </AlertDialog>
                        </div>
                    </div>

                </div>
            </div>}

            <PostTemplateSelectionModal selectedPostTemplate={selectedPostTemplate}
                                        setContent={setContent}
                                        setSelectedPostTemplate={setSelectedPostTemplate}
                                        open={showTemplateSelectionModal} setOpen={setShowTemplateSelectionModal}/>
        </div>
    )
}

CreateSocialPost.propTypes = {
    languages: PropTypes.array,
    socialPost: PropTypes.object
};

export default CreateSocialPost
