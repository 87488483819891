import * as React from "react"
import * as SliderPrimitive from "@radix-ui/react-slider"

import {cn} from "../../lib/utils"
import {clsx} from "clsx";

const Slider = React.forwardRef<
    React.ElementRef<typeof SliderPrimitive.Root>,
    React.ComponentPropsWithoutRef<typeof SliderPrimitive.Root>
>(({className, ...props}, ref) => (
    <>
        <SliderPrimitive.Root
            ref={ref}
            className={cn(
                "relative flex w-full touch-none select-none items-center",
                className
            )}
            {...props}
        >
            <SliderPrimitive.Track className="relative h-2 w-full grow overflow-hidden rounded-full bg-secondary">
                <SliderPrimitive.Range className="absolute h-full bg-primary"/>
            </SliderPrimitive.Track>
            <SliderPrimitive.Thumb
                className="block h-5 w-5 rounded-full border-2 border-primary bg-background ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50"/>
        </SliderPrimitive.Root>
        {className?.includes("showStepBar") && <div className='mt-1.5 flex w-full flex-row justify-between'>
            {Array.from({length: (props.max - props.min) / props.step + 1}).map((_, i) => (
                <span
                    key={`${name}-${i}`}
                    className={clsx('text-sm', {
                        'font-bold': props.value && props.value.length > 0 && props.min + i * props.step === props.value[0],
                        'text-10': i > 0 && i < (props.max - props.min) / props.step
                    })}
                    role='presentation'
                >
      {props.min + i * props.step}
    </span>
            ))}
        </div>}
    </>

))
Slider.displayName = SliderPrimitive.Root.displayName

export {Slider}
