import React from "react"
import PropTypes from "prop-types"
const GrinIcon = ({ className }: {className?: string}) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth="1.5"
        stroke="currentColor"
        className={className}
    >
        <path strokeLinecap="round" strokeLinejoin="round" d="M12 21.75c5.385 0 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25 2.25 6.615 2.25 12 6.615 21.75 12 21.75z" />
        <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 15.75a4.5 4.5 0 01-7.5 0M9 9.75h.008v.008H9v-.008zM15 9.75h.008v.008H15v-.008z" />
    </svg>
);

export default GrinIcon;
