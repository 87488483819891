import React, {useEffect, useState} from "react"
import useSound from "use-sound";
import {Pause, Play} from "lucide-react";
import PropTypes from "prop-types"

const Player = ({src}) => {
    const [isPlaying, setIsPlaying] = useState<boolean>(false);
    const [time, setTime] = useState<{ min: number; sec: number }>({min: 0, sec: 0});
    const [currTime, setCurrTime] = useState<{ min: number; sec: number }>({min: 0, sec: 0});
    const [seconds, setSeconds] = useState<number | undefined>();

    const [play, {pause, duration, sound}] = useSound(src , {
        onend: () => {
            setIsPlaying(false);
        }
    });

    useEffect(() => {
        if (duration) {
            const sec = duration / 1000;
            const min = Math.floor(sec / 60);
            const secRemain = Math.floor(sec % 60);
            setTime({
                min: min,
                sec: secRemain,
            });
        }
    }, [duration]);

    useEffect(() => {
        const interval = setInterval(() => {
            if (sound) {
                setSeconds(sound.seek());
                const min = Math.floor(sound.seek() / 60);
                const sec = Math.floor(sound.seek() % 60);
                setCurrTime({
                    min,
                    sec,
                });
            }
        }, 1000);
        return () => clearInterval(interval);
    }, [sound]);

    const playingButton = () => {
        if (isPlaying) {
            pause();
            setIsPlaying(false);
        } else {
            play();
            setIsPlaying(true);
        }
    };

    return (
        <div className="max-w-xs mx-auto bg-white rounded-lg shadow-lg overflow-hidden">
            <div className="px-4 pb-4 justify-center items-center flex flex-col" >
                <button className="rounded-full p-3 bg-primary text-white mt-4 mx-4" onClick={playingButton}>
                    {isPlaying ? <Pause/> : <Play/>}
                </button>
                <div className="flex flex-row items-center justify-center">
                    <span>{currTime.min}:{currTime.sec < 10 ? `0${currTime.sec}` : currTime.sec}</span>
                    <input
                        type="range"
                        min="0"
                        max={duration ? duration / 1000 : 0}
                        defaultValue="0"
                        value={seconds}
                        className="block w-full h-px bg-gray-300 mx-4"
                        onChange={(e) => {
                            if (sound) sound.seek(Number(e.target.value));
                        }}
                    />
                    <span>{time.min}:{time.sec < 10 ? `0${time.sec}` : time.sec}</span>
                </div>
            </div>
        </div>
    );
};

Player.propTypes = {
    src: PropTypes.string.isRequired,
};

export default Player;
