import React, {useState} from 'react';

const PostTemplateCard = ({template, setSelectedPostTemplate, selectedPostTemplate, setContent, setShowTemplateSelectionModal}) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const maxLength = 200;
    const text = template.body;

    const onUseTemplateClick = () => {
        setSelectedPostTemplate(template);
        setContent(template.body);
        setShowTemplateSelectionModal(false);
    }

    return (
        <div className="p-4 max-w-xs bg-white rounded-lg shadow-md">
            <p className={`text-gray-800 text-sm whitespace-pre-line cursor-pointer ${isExpanded ? "" : "line-clamp-[10]"}`}
               onClick={() => setIsExpanded(!isExpanded)}>
                {text}
            </p>

            <button onClick={() => onUseTemplateClick()}
                    className="w-full px-4 py-2 border border-gray-300 rounded-md bg-gray-100 text-gray-700 flex justify-between items-center mt-4">
                Use as Template
                <svg className="h-4 w-4 text-gray-500" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                     xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 12h14M12 5l7 7-7 7"></path>
                </svg>
            </button>
        </div>
    );
};

export default PostTemplateCard;
