import React, {useState, useEffect} from 'react';
import {Meh, Smile, Frown, Angry, Laugh} from 'lucide-react';
import GrinIcon from './GrinIcon';
import {showJetToast} from "../Toast";
import TextareaAutosize from "react-textarea-autosize";  // Import the GrinIcon component

const FeedbackForm = ({feedbackableType, feedbackableId}) => {
    const [rating, setRating] = useState(null); // Default rating to happy smiley
    const [comment, setComment] = useState('');
    const [feedbackId, setFeedbackId] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [submitLoading, setSubmitLoading] = useState(false);

    useEffect(() => {
        // Fetch existing feedback if available
        const fetchFeedback = async () => {
            try {
                const response = await fetch(`/shorts/${feedbackableId}/feedbacks`);
                if (response.ok) {
                    const data = await response.json();
                    if (data.id) {
                        setRating(data.rating);
                        setComment(data.comment);
                        setFeedbackId(data.id);
                    }
                }
            } catch (error) {
                console.error('Error fetching feedback:', error);
            }
            setLoading(false);
        };

        fetchFeedback();
    }, [feedbackableId]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!rating) {
            setError('Please provide a rating.');
            return;
        }

        const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

        try {
            setSubmitLoading(true);
            const response = await fetch(`/shorts/${feedbackableId}/feedbacks${feedbackId ? `/${feedbackId}` : ''}`, {
                method: feedbackId ? 'PATCH' : 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-Token': csrfToken
                },
                body: JSON.stringify({feedback: {rating, comment}}),
            });

            if (response.ok) {
                showJetToast('Feedback submitted successfully!');
            } else {
                const errorData = await response.json();
                showJetToast(errorData.error || 'Failed to submit feedback.');
            }
            setError(null);
        } catch (error) {
            showJetToast('Failed to submit feedback.');
        } finally {
            setSubmitLoading(false);
        }
    };

    if (loading) {
        return (
            <div className="p-4 max-w-lg mx-auto">
                <div className="animate-pulse flex space-x-4">
                    <div className="rounded-full bg-gray-200 h-12 w-12"></div>
                    <div className="flex-1 space-y-4 py-1">
                        <div className="h-4 bg-gray-200 rounded w-3/4"></div>
                        <div className="space-y-2">
                            <div className="h-4 bg-gray-200 rounded"></div>
                            <div className="h-4 bg-gray-200 rounded w-5/6"></div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    const iconClass = "w-8 h-8 lg:w-14 lg:h-14"

    return (
        <div className="p-4 max-w-lg mx-auto">
            <form onSubmit={handleSubmit} className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2">
                        How would you rate the generated video?
                    </label>
                    <div className="flex space-x-4 justify-center">
                        {[1, 2, 3, 4, 5].map((value) => (
                            <button
                                key={value}
                                type="button"
                                className={`text-5xl ${rating === value ? 'text-accent' : 'text-gray-300'} transform transition-transform duration-300`}
                                onClick={() => setRating(value)}
                            >
                                {value === 1 ? <Angry className={iconClass}/> : value === 2 ?
                                    <Frown className={iconClass}/> : value === 3 ?
                                        <Meh className={iconClass}/> : value === 4 ? <Smile className={iconClass}/> :
                                            <Laugh className={iconClass}/>}
                            </button>
                        ))}
                    </div>
                </div>
                <div className="mb-6">
                    <label className="block text-gray-700 text-sm font-bold mb-2">
                        Comment (Optional)
                    </label>
                    <TextareaAutosize
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                        minRows={2}
                        className="block shadow rounded-md border border-gray-200 outline-none px-3 py-2 mt-2 w-full"
                    />
                </div>
                {error && <p className="text-red-500 text-xs italic">{error}</p>}
                <div className="flex items-center justify-between">
                    {submitLoading ? <div className="rounded-lg py-3 text-center bg-primary">
                        <div className={"text-white"}>
                            <svg className="animate-spin inline h-6 w-6 mx-3" viewBox="0 0 24 24">
                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                                        strokeWidth="4"/>
                                <path className="opacity-75" fill="currentColor"
                                      d="M4 12a8 8 0 018-8v1a7 7 0 00-7 7h1z"/>
                            </svg>
                        </div>
                    </div> : <button
                        type="submit"
                        className="rounded-lg font-bold py-3 text-center cursor-pointer px-5 block bg-primary text-textPrimary"
                    >
                        Submit
                    </button>}
                </div>
            </form>
        </div>
    );
};

export default FeedbackForm;
