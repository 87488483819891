import React, {useEffect, useState} from "react"
import PropTypes from "prop-types"
import PostTemplateCard from "./PostTemplateCard";

const PostTemplateList = ({selectedPostTemplate, setSelectedPostTemplate, setContent, setShowTemplateSelectionModal}) => {

    const [templates, setTemplates] = useState([]);

    const fetchTemplates = async () => {
        try {
            const response = await fetch("/post_templates.json",{
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    // @ts-ignore
                    "X-CSRF-Token": document.querySelector('[name="csrf-token"]').content
                }
            });
            const data = await response.json();
            setTemplates(data);
        } catch (error) {
            console.error("Error fetching templates", error);
        }
    }

    useEffect(() => {
        fetchTemplates();
    }, []);

    return (
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
            {templates.map((template) => (
                <PostTemplateCard
                    key={template.id} template={template}
                    setContent={setContent}
                    setShowTemplateSelectionModal={setShowTemplateSelectionModal}
                    setSelectedPostTemplate={setSelectedPostTemplate}
                    selectedPostTemplate={selectedPostTemplate}/>
            ))}
        </div>
    )
}

PostTemplateList.propTypes = {
};

export default PostTemplateList
