import React, {useEffect, useState} from "react"
import PropTypes from "prop-types"
import {Input} from "../ui/input";
import ContentEditable from "../common/ContentEditable"

const CreateSocialPostFromInput = ({type, typeValue, setTypeValue, inputData, setInputData}) => {

    if (type === "Topic") {
        return (
            <div className="p-1">
                <Input
                    value={typeValue}
                    onChange={(e) => setTypeValue(e.target.value)}
                    className="overflow-x-scroll focus:border-transparent focus:ring-0 focus:outline-none shadow h-12"
                    type="text" placeholder="Enter topic/keywords here. e.g. why most startups fail" />
            </div>
        )
    } else if (type === "Link") {
        return (
            <div className="py-2">
                <Input
                    value={typeValue}
                    className="overflow-x-scroll focus:border-transparent focus:ring-0 focus:outline-none shadow h-12"
                    onChange={(e) => setTypeValue(e.target.value)}
                    type="text" placeholder="Paste link here. e.g. https://example.com"/>
                <Input
                    value={inputData?.["additional_prompt"] || ""}
                    onChange={(e) => setInputData({...inputData, additional_prompt: e.target.value})}
                    className="overflow-x-scroll w-full lg:w-[80%] mt-4 focus:border-transparent focus:ring-0 focus:outline-none shadow h-12"
                    type="text" placeholder="(Optional) Additional instructions to AI like focus on how it is similar to X"/>
            </div>
        )
    } else if (type === "Text") {
        return (
            <div className="py-2">
                <ContentEditable
                    html={typeValue}
                    disabled={false}
                    onChange={(e) => setTypeValue(e.target.value)}
                    className={`h-64 overflow-scroll block shadow rounded-md border border-gray-200 outline-none px-3 py-2 mt-2 w-full`}
                />
            </div>
        )
    } else if (type === "Youtube") {
        return (
            <div className="py-2">
                <Input
                    value={typeValue}
                    onChange={(e) => setTypeValue(e.target.value)}
                    className="overflow-x-scroll focus:border-transparent focus:ring-0 focus:outline-none shadow h-12"
                    type="text" placeholder="Paste youtube link here. e.g. https://www.youtube.com/watch?v=example"/>
                <Input
                    value={inputData?.["additional_prompt"] || ""}
                    onChange={(e) => setInputData({...inputData, additional_prompt: e.target.value})}
                    className="overflow-x-scroll w-full lg:w-[80%] mt-4 focus:border-transparent focus:ring-0 focus:outline-none shadow h-12"
                    type="text" placeholder="(Optional) Additional instructions to AI like focus on how the video is good for X"/>
            </div>
        )
    }

    return (
        <div>
        </div>
    )
}

CreateSocialPostFromInput.propTypes = {
};

export default CreateSocialPostFromInput
