import React, {useEffect} from "react";
import {CarouselData, CarouselSlide, ThemeSettings} from "../../types/carouselData";
import {CirclePlus, Trash2} from "lucide-react";
import {Button} from "../ui/button";
import {Avatar, AvatarFallback, AvatarImage} from "../ui/avatar";
import ContentEditable from "../common/ContentEditable";
import themesData from "../../data/themes.json";
import {TemplateProps} from "./TemplateOne";


export const TemplateModern: React.FC<TemplateProps> = ({
                                                            carouselData,
                                                            index,
                                                            onDeleteClick,
                                                            onAddClick,
                                                            onCarouselTitleChange,
                                                            onTitleChange,
                                                            onDescriptionChange,
                                                            height = "600px",
                                                            width = "480px",
                                                            hideIcons = false,
                                                            isPaidUser = false,
                                                        }) => {
    const slide = index >= 0 && index < (carouselData.data && carouselData.data.slides.length) ? carouselData.data.slides[index] : null;
    const theme = carouselData.theme_settings || themesData[1];

    if (index == -1) {
        return (
            <div
                className={`p-6 pdf-page w-[${width}] hover:border-primary max-w-[${width}] h-[${height}] h-[100%] flex flex-col aspect-[480/600] overflow-hidden relative`}
                key={index}
                style={{
                    backgroundColor: theme.background_one_color, letterSpacing: "0.01px",
                    fontFamily: "Poppins, sans-serif"
                }}>
                <div className="flex items-center">
                    <div className="w-12 h-12 rounded-full flex items-center justify-center">
                        <Avatar>
                            <AvatarImage src={carouselData.image_url}/>
                        </Avatar>
                    </div>
                    <div className="ml-2">
                        <p className="font-bold text-sm"
                           style={{color: theme.heading_one_color}}>{carouselData.name}</p> {/* Name */}
                        <p className="text-gray-500 text-sm">{carouselData.social_handle}</p> {/* Handle */}
                    </div>
                </div>
                <ContentEditable
                    className="font-bold mt-14 text-5xl whitespace-normal break-words w-[95%] block outline-none bg-transparent border-none focus:ring-0 focus:outline-none"
                    html={carouselData.data?.title} // innerHTML of the editable div
                    disabled={false}
                    style={{color: theme.heading_one_color}}
                    onChange={(e) => onCarouselTitleChange(e.target.value)}
                />
                <div
                    className="absolute bottom-8 mx-8 p-2 flex items-center justify-end w-[80%]"> {/* Dark green background */}
                    <div className="flex flex-row">
                        <img src={"/icons/swipe_hand.png"} alt="swipe hand" className="w-12 h-12 z-[100]"/>
                        <img src={"/icons/swipe_right.png"} alt="swipe hand" className="w-10 h-10 -ml-3 mt-2"/>
                    </div>
                </div>
            </div>

        )
    }

    if (index == 999) {
        return (
            <div
                className={`p-6 pdf-page w-[${width}] hover:border-primary max-w-[${width}] h-[${height}] h-[100%] flex flex-col items-center aspect-[480/600] overflow-hidden relative`}
                key={index} style={{
                backgroundColor: theme.background_one_color, letterSpacing: "0.01px",
                fontFamily: "Poppins, sans-serif"
            }}>
                <div className="text-center mt-8 flex flex-col justify-center items-center">
                    <Avatar className="w-[100px] h-[100px] border-2" style={{
                        borderColor: theme.heading_one_color,
                    }}>
                        <AvatarImage src={carouselData.image_url}/>
                    </Avatar>
                    <div className="mt-4 text-lg" style={{color: theme.heading_one_color}}>{carouselData.name}</div>
                </div>
                <div className="text-center mt-12 w-[80%] flex flex-col items-center">
                    <h1 className="font-bold text-4xl"
                        style={{color: theme.heading_one_color}}>{carouselData.call_to_action_text}</h1>
                    <p className="mt-4 px-4 py-2 text-2xl font-semibold rounded-full" style={{
                        color: theme.background_one_color,
                        backgroundColor: theme.heading_one_color
                    }}>{carouselData.call_to_action_button_text}</p>
                </div>
                {!isPaidUser ? <div className="absolute bottom-4 pb-1 mx-8"
                                    style={{color: theme.heading_one_color}}
                >
                    Made with KolPost.com
                </div> : <></>}
            </div>
        )
    }

    return (
        <div
            className={`p-6 pdf-page w-[${width}] hover:border-primary max-w-[${width}] h-[${height}] h-[100%] flex flex-col items-center aspect-[480/600] overflow-hidden relative`}
            key={index} style={{
            backgroundColor: theme.background_content_color,
            fontFamily: "Poppins, sans-serif"
        }}>
            {!hideIcons && <div className="absolute top-[8px] right-[8px] flex flex-row gap-2">
                <div aria-label="Add" className="icon" onClick={() => onAddClick(index)}>
                    <CirclePlus className="w-5 h-5 text-gray-600 hover:text-gray-800"/>
                </div>
                <div aria-label="Delete" className="icon" onClick={() => onDeleteClick(index)}>
                    <Trash2 className="w-5 h-5 text-gray-600 hover:text-gray-800"/>
                </div>
            </div>}
            <ContentEditable
                className="w-full mt-6 font-bold block outline-none text-5xl whitespace-normal break-words bg-transparent border-none focus:ring-0 focus:outline-none"
                html={slide.title} // innerHTML of the editable div
                disabled={false}
                style={{color: theme.heading_content_color}}
                onChange={(e) => onTitleChange(index, e.target.value)} // handle innerHTML change
            />
            <ContentEditable
                className="w-full mt-8 font-bold block outline-none text-4xl whitespace-normal break-words bg-transparent border-none focus:ring-0 focus:outline-none"
                html={slide.description} // innerHTML of the editable div
                disabled={false}
                style={{color: theme.content_color}}
                onChange={(e) => onDescriptionChange(index, e.target.value)} // handle innerHTML change
            />
            <div
                className="absolute bottom-8 mx-8 p-2 flex items-center justify-end w-[80%]"> {/* Dark green background */}
                <div className="flex flex-row">
                    <img src={"/icons/swipe_hand.png"} alt="swipe hand" className="w-12 h-12 z-[100]"/>
                    <img src={"/icons/swipe_right.png"} alt="swipe hand" className="w-10 h-10 -ml-3 mt-2"/>
                </div>
            </div>
        </div>
    );
};

